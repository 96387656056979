<script lang="ts" setup>
	import Hls from "hls.js";

	const props = defineProps<{
		muted: boolean;
		autoplay: boolean;
		loop: boolean;
		src: string;
		poster: string;
	}>();

	const video = ref<HTMLVideoElement | null>(null);

	let hls: Hls | null = null;

	onMounted(() => {
		watch(
			() => props.src,
			() => {
				destroy();
				build();
			},
			{ immediate: true }
		);
	});

	function build() {
		const reference = video.value;

		if (reference) {
			if (reference.canPlayType("application/vnd.apple.mpegurl")) {
				// nothing to do
			} else if (Hls.isSupported()) {
				// set up HLS
				var hls = new Hls();
				hls.loadSource(props.src);
				hls.attachMedia(reference);
			}
		}
	}

	function destroy() {
		if (hls) {
			hls.destroy();
			hls = null;
		}
	}

	onUnmounted(() => {
		destroy();
	});
</script>

<template>
	<video ref="video" class="hls-video" :src="props.src" :poster="poster" :loop="loop" :muted="muted" :autoplay="autoplay" playsinline></video>
</template>

<style scoped lang="scss">
	.hls-video {
		object-fit: cover;
		object-position: center center;
	}
</style>
